import './App.css';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react'
import {AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn} from "@aws-amplify/ui-react";
import awsconfig from './aws-exports';
import AppRouter from './Router';
Amplify.configure(awsconfig);

const App = () => {
  return (
      <div>
      <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignUp
              slot="sign-up"
              usernameAlias="email"
              formFields={[
                  {
                      type: "email",
                      label: "Email",
                      placeholder: "Email",
                      inputProps: { required: true, autocomplete: "username" },
                  },
                  {
                      type: "name",
                      label: "Name",
                      placeholder: "Name",
                      inputProps: { required: true, autocomplete: "username" },
                  },
                  {
                      type: "password",
                      label: "Password",
                      placeholder: "password",
                      inputProps: { required: true, autocomplete: "new-password" },
                  },
                  {
                      type: "phone_number",
                      label: "Phone Number",
                      placeholder: "phone",
                  },
              ]}
          />
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
      </AmplifyAuthenticator>
          <div className="App container py-4">
              <AppRouter />
          </div>
      </div>
  );
}

export default App
