import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';
import { ButtonGroup, Button } from "react-bootstrap";

// const match = {
//     'id' : 10244,
//     'date' : "2021-10-16T18:00:00.000",
//     'name' : "Heracles - AZ Alkmaar",
//     'home' : "Heracles",
//     'homeId' : 206,
//     'homeGoals' : null,
//     'away' : "AZ Alkmaar",
//     'awayId' : 201,
//     'awayGoals' : null,
//     'gameState' : 'NS',
//     'gameResult' : null
// }

let apiName = 'topitoApi';
let path = '/fixture/:id'; // should be something like fixtures, sorted by date [DESC]

export default class Bets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bet: 'tie',
            match: {},
            msg: ''
        }
    }

    getItem(fixtureId){
        API.get(apiName, '/mybets', {}).then(response => {
            console.log(response);
        });

        API.get(apiName, path.replace(':id', fixtureId), {}).then(response => {
            this.setState({
                match: response,
            });
        });
    }

    componentDidMount(){
        const { fixtureId } = this.props.match.params;
        this.getItem(fixtureId)
    }

    setBet(event) {
        this.setState({
            bet: event.target.getAttribute('value')
        })
    }

    placeBet(event) {
        const match = this.state.match;
        const bet = this.state.bet;
        Auth.currentUserInfo().then(data => {
            const myInit = {
                body: {
                    fixtureId: match.id,
                    email: data.attributes.email,
                    bet,
                    credits: event.target.getAttribute('value')
                },
                headers: {},
            };

            // console.log('Sent bet!:', JSON.stringify(myInit));
            API.post(apiName, '/bets', myInit).then(response => {
                this.setState({
                    msg: response.success
                })
            });

        })
    }
    getMsg() {
        const msg = this.state.msg;
        return msg;
    }

    getHomeBetButtonColor() {
        const bet = this.state.bet;
        return (bet === 'home' ? 'success' : (bet !== 'tie' ? 'danger' : 'secondary'));
    }

    getAwayBetButtonColor() {
        const bet = this.state.bet;
        return (bet === 'away' ? 'success' : (bet !== 'tie' ? 'danger' : 'secondary'));
    }

    getTieBetButtonColor() {
        const bet = this.state.bet;
        return (bet === 'tie' ? 'success' : 'secondary');
    }

    render() {
        const match = this.state.match;
        const bet = this.state.bet;
        return (
            <div key={match.id} className="d-flex mx-5 flex-column">
                <Match match={match}/>

                <div className="d-flex justify-content-center align-items-center mx-5">
                    <label>Select outcome: <span>{bet}</span></label>
                </div>
                <div className="d-flex justify-content-center align-items-center mx-5">
                    <ButtonGroup aria-label="Basic example">
                        <Button onClick={this.setBet.bind(this)} value="home" variant={this.getHomeBetButtonColor()}>{match.home} wins</Button>
                        <Button onClick={this.setBet.bind(this)} value="tie" variant={this.getTieBetButtonColor()} active>Tie</Button>
                        <Button onClick={this.setBet.bind(this)} value="away" variant={this.getAwayBetButtonColor()}>{match.away} wins</Button>
                    </ButtonGroup>
                </div>
                <div className="d-flex justify-content-center align-items-center mx-5">
                    <label>Choose amount of credits: </label>
                </div>
                <div className="d-flex justify-content-center align-items-center mx-5">
                    <button onClick={this.placeBet.bind(this)} value="10" className="btn btn-success">10</button>&nbsp;
                    <button onClick={this.placeBet.bind(this)} value="50" className="btn btn-success">50</button>&nbsp;
                    <button onClick={this.placeBet.bind(this)} value="100" className="btn btn-success">100</button>
                </div>
                <div className="d-flex justify-content-center align-items-center mx-5">
                    <label>{this.state.msg}</label>
                </div>
            </div>
        )
    }
}

function teamLogo(teamId) {
    return `https://media.api-sports.io/football/teams/${teamId}.png`;
}

function Match(props) {
    return (
        <div className="d-flex justify-content-between align-items-center mx-5">
            <div className="d-flex justify-content-center">
                <img className="team-logo" src={teamLogo(props.match.homeId)} alt="home" />
            </div>
            <h1>-</h1>
            <div className="d-flex justify-content-center">
                <img className="team-logo" src={teamLogo(props.match.awayId)} alt="away" />
            </div>
        </div>
    )
}