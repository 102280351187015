import React, { Component } from 'react';
import { API } from 'aws-amplify';
import awsconfig from '../../aws-exports';

API.configure(awsconfig)

const apiName = 'topitoApi';
const path = '/users';
const users = []

export default class Scoreboard extends Component {
  scores = [];

  constructor() {
    super();

    this.state = {
      users: this.sortUsers(users)
    }
  }

  getItems() {
    API
        .get(apiName, path)
        .then(response => {
          console.log(response)
        });
  }

  sortUsers(users) {
    return users.sort((a,b) => b.credits - a.credits)
  }

  componentDidMount(){
    // this.getItems()
  }

  render () {
    this.scores = [];
    this.state.users.forEach((user, i) => this.scores.push(<Score key={i} email={user.email} credits={user.credits} />));
    
    return (
      <div className="d-flex mx-5 flex-column">
        {this.scores}
      </div>
    )
  }
}

function Score(props) {
  return (
    <div className="d-flex justify-content-between mx-5 my-2">
      <span>{props.email}</span>
      <span>{props.credits}</span>
    </div>
  )
}