import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {AmplifySignOut} from "@aws-amplify/ui-react";


import Scoreboard from './components/Scoreboard/Scoreboard';
import Matches from './components/Matches/Matches';
import Profile from './components/Profile/Profile';
import Bets from './components/Bets/Bets';



export default function AppRouter() {
  return (
    <Router>
      <AmplifySignOut buttonText="LOG OUT"></AmplifySignOut>
    <div className="container-fluid bg-light p-5">
      <h1 className="display-5 fw-bold">Welcome to Topito!</h1>
      <p className="col-md-8 fs-4">Place your bets on matches in the highly competitive Eredivisie!</p>
      <div className="row align-items-stretch">
        <div className="col">  
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Scoreboard</h5>
              <p className="card-text">View the Scoreboard and see the standing of all Topito users.</p>
              <Link to="/" className="btn btn-primary">Scoreboard</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Matches</h5>
              <p className="card-text">Place your bets on upcoming matches and earn credits.</p>
              <Link to="/matches" className="btn btn-primary">Matches</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Profile</h5>
              <p className="card-text">A quick overview of your profile. With all your statistics.</p>
              <Link to="/profile" className="btn btn-primary">My Profile</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="m-3">
      <Switch>
        <Route path="/matches">
          <Matches />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/bets/:fixtureId" render={(props) => <Bets {...props}/>} />
        <Route path="/">
          <Scoreboard />
        </Route>
      </Switch>
    </div>
    </Router>
  );
}