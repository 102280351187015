/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:931271db-00f4-403e-b868-9e1b36a1b975",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_EDZzU4V9X",
    "aws_user_pools_web_client_id": "3rn4om6efj1f18f7jum0in0ek3",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "teams-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "fixtures-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "bets-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "users-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "topitoApi",
            "endpoint": "https://cfo9sn9zv2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
