import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import dayjs from "dayjs";

const matches = [
    {
        'id' : 10244,
        'date' : "2021-10-16T18:00:00.000",
        'name' : "Heracles - AZ Alkmaar",
        'home' : "Heracles",
        'homeId' : 206,
        'homeGoals' : null,
        'away' : "AZ Alkmaar",
        'awayId' : 201,
        'awayGoals' : null,
        'gameState' : 'NS',
        'gameResult' : null
    },
    {
        'id' : 10245,
        'date' : "2021-10-16T19:00:00.000",
        'name' : "Vitesse - Ajax",
        'home' : "Vitesse",
        'homeId' : 200,
        'homeGoals' : 2,
        'away' : "Ajax",
        'awayId' : 202,
        'awayGoals' : 1,
        'gameState' : 'FT',
        'gameResult' : 'home'
    },
]

/*
 https://www.api-football.com/documentation-v3#operation/get-fixtures-rounds
*/
const gameStates = {
    'TBD': 'Time To Be Defined',
    'NS': 'Not Started',
    '1H': 'First Half, Kick Off',
    'HT': 'Halftime',
    '2H': 'Second Half, 2nd Half Started',
    'ET': 'Extra Time',
    'P': 'Penalty In Progress',
    'FT': 'Match Finished',
    'AET': 'Match Finished After Extra Time',
    'PEN': 'Match Finished After Penalty',
    'BT': 'Break Time (in Extra Time)',
    'SUSP': 'Match Suspended',
    'INT': 'Match Interrupted',
    'PST': 'Match Postponed',
    'CANC': 'Match Cancelled',
    'ABD': 'Match Abandoned',
    'AWD': 'Technical Loss',
    'WO': 'WalkOver',
    'LIVE': 'In Progress',
};

const gameNotStartedStates = ['TBD', 'NS'];

let apiName = 'topitoApi';
let path = '/fixturelist'; // should be something like fixtures, sorted by date [DESC]
// let path = '/placeBet'; // should be something like fixtures, sorted by date [DESC]

export default class Matches extends Component {

  constructor() {
    super();
    this.state = {
        matches: []
    }
  }

  sort(matches) {
      return matches.sort((a,b) => {
          return new Date(a.date).getTime() -
              new Date(b.date).getTime()
      })
  }

  getItems(){
    API.get(apiName, path, {}).then(response => {
        this.setState({
            matches: this.sort(response)
        });
    });
  }

  componentDidMount(){
    this.getItems()
  }
 
  render() {
    const matches = this.state.matches;

    var matchList = matches.map(match => (
        <div key={match.id} className="d-flex mx-5 flex-column">
            <Match match={match}/>
        </div>
        )
    )
    return <div> { matchList } </div>;
  }
}

function teamLogo(teamId) {
    return `https://media.api-sports.io/football/teams/${teamId}.png`;
}

function betBtnClass(disabled) {
    const btnClass = disabled ? 'btn-secondary' : 'btn-primary';
    return `btn ${btnClass}`;
}

function getBetLink(fixtureId) {
    return `/bets/${fixtureId}`;
}

function getDateOfMatch(match) {
    return dayjs(match.date).format('dddd D MMM YYYY  HH:mm')
}

function Match(props) {
  const disabledBetting = gameNotStartedStates.indexOf(props.match.gameState) === -1;
  return (
    <div className="d-flex justify-content-between align-items-center mx-5">
          <span>{getDateOfMatch(props.match)}</span>
          <div className="d-flex justify-content-center">
            <img className="team-logo" src={teamLogo(props.match.homeId)} alt="home" />
          </div>
          { props.match.gameResult === 'home' ? <button type="button" className="btn btn-success">win</button> : null }
          { props.match.gameResult === 'away' ? <button type="button" className="btn btn-danger">loss</button> : null }
          <span>{(props.match.homeGoals)}</span>
          <h1>-</h1>
          <span>{(props.match.awayGoals)}</span>
          { props.match.gameResult === 'away' ? <button type="button" className="btn btn-success">win</button> : null }
          { props.match.gameResult === 'home' ? <button type="button" className="btn btn-danger">loss</button> : null }
          <div className="d-flex justify-content-center">
            <img className="team-logo" src={teamLogo(props.match.awayId)} alt="away" />
          </div>
          <div className="d-flex justify-content-center">
              <Link to={getBetLink(props.match.id)} className={betBtnClass(disabledBetting)} disabled={disabledBetting}>Bet</Link>
              <span>&nbsp;{gameStates[props.match.gameState]}</span>
          </div>
    </div>
  )
}